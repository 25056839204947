.choreoEditor {
  display: flex;
  max-width: 100vw;
  overflow: scroll;
  padding: 7em 0em;
  margin-right: 1em;
}

.stepEditor {
  border: 1px solid black;
  border-radius: 5px;
  flex-direction: row;
  margin: 1rem;
  padding: 3rem;
  max-width: 12em;
}

.stepEditor.is-new {
  border: 1px dashed black;
}

.stepSelection {
  margin: 10px;
  cursor: pointer;
}

.actions {
  display: flex;
}
