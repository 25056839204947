.button {
  background: #ffffff;
  border: 4px solid #494949 !important;
  color: #494949 !important;
  cursor: pointer;
  display: inline-block;
  padding: 20px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
}

.button:hover {
  background: #176bab;
  border-color: #176bab !important;
  color: #ffffff !important;
  transition: all 0.4s ease 0s;
}
